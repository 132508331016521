import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function Footer() {
    return (
        <React.Fragment>
            <p className="footer">Copyright <FontAwesomeIcon icon={faCopyright} size="2xs" /> 2023. PULPTICKET.COM. All Rights Reserved. </p>
        </React.Fragment>

    );
}
export default Footer;